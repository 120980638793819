function hide() {
  if (document.getElementById(`_notification-badge`)) {
    document.getElementById(`_notification-badge`).classList.add('_hidden');
  }
  const notificationCards =
    document.getElementsByClassName('_notification_card');
  [...notificationCards].forEach((n) => {
    if (n.classList.contains('_bg-blue-5') && n.classList.contains('_seen')) {
      n.classList.remove('_bg-blue-5');
    } else if (!n.classList.contains('_seen')) {
      n.classList.add('_seen');
    }
  });
}

window.addEventListener('load', () => {
  const triggerShowElements = document.querySelectorAll(
    '._notification-count-update'
  );
  triggerShowElements.forEach((elem) => {
    elem.addEventListener('click', () => {
      hide();
    });
  });
});
